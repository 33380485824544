import React from 'react';

const Project = () => {
    return (
        <>
            <h1>This is my Project details.</h1>
        </>
    )
}

export default Project;